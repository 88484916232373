import dedent from 'dedent';
// eslint-disable-next-line
import Worker from 'worker-loader!./webworker.js';

const pyodideWorker = new Worker();
let counter = 0;
const callbacks = {};

pyodideWorker.onerror = (e) => {
  console.error(`Python worker totally failed task with error:${e}`);
};

pyodideWorker.onmessage = (e) => {
  const taskCounter = e.data.incrementCounter;
  if (!callbacks[taskCounter]) {
    console.error(
      `Python worker returned result for unknown task ${taskCounter}.`
    );
  } else {
    const { onSuccess, onError } = callbacks[taskCounter];
    delete callbacks[taskCounter];
    if ('error' in e.data) {
      onError(new Error(e.data.error));
    } else if ('result' in e.data) {
      onSuccess(e.data.result);
    } else {
      console.error(
        `Python worker returned result for task ${taskCounter} that lacks result or error.`
      );
    }
  }
};

const baseRun = (script, context, onSuccess, onError) => {
  const dedentedScript = dedent(script);
  const incrementCounter = counter;
  counter += 1;
  console.log(`Submitting Python command ${incrementCounter} to worker:\n${dedentedScript}`);
  callbacks[incrementCounter] = { onSuccess, onError };
  pyodideWorker.postMessage({
    ...context,
    pythonCode: dedentedScript,
    incrementCounter,
  });
};

const runPythonAsync = (script, context) =>
  new Promise((onSuccess, onError) => {
    baseRun(script, context, onSuccess, onError);
  });

export { runPythonAsync as default };
