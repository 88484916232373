import { React } from 'react';
import {
  Button,
  Card,
  ControlGroup,
  Elevation,
  FormGroup,
  H3,
  InputGroup,
} from '@blueprintjs/core';

const MailchimpSubscribe = () => (
  <Card elevation={Elevation.TWO} style={{margin: "7px"}}>
    <H3>Stay in touch?</H3>
    <form
      action="https://lukemerrick.us5.list-manage.com/subscribe/post?u=6426be28e01b0c79070065224&amp;id=d077804f4e"
      method="post"
      id="mc-embedded-subscribe-form"
      name="mc-embedded-subscribe-form"
      target="_blank"
      noValidate
    >
      <div style={{ display: 'flex', flexDirection: 'row' }}>
        <ControlGroup fill large>
          <FormGroup
            label="Email Address"
            labelInfo="(required)"
            labelFor="mce-EMAIL"
          >
            <InputGroup
              large
              type="email"
              name="EMAIL"
              id="mce-EMAIL"
              placeholder="Your email address goes here"
            />
          </FormGroup>

          <FormGroup
            label="Feedback"
            labelInfo="(optional)"
            labelFor="mce-FEEDBACK"
          >
            <InputGroup
              large
              type="text"
              placeholder="Praise, suggestions, puns, etc."
              name="FEEDBACK"
              id="mce-FEEDBACK"
            />
          </FormGroup>
          <div
            style={{ position: 'absolute', left: '-5000px' }}
            aria-hidden="true"
          >
            <InputGroup
              type="text"
              name="b_6426be28e01b0c79070065224_d077804f4e"
              tabIndex="-1"
            />
          </div>
          <FormGroup style={{justifyContent: "flex-end"}}>
            <Button large icon="envelope" type="submit" id="mc-embedded-subscribe">
              Submit
            </Button>
          </FormGroup>
        </ControlGroup>
      </div>
    </form>
  </Card>
);

export default MailchimpSubscribe;
