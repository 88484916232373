import React from 'react';
import { Button, Card, Elevation, H3 } from '@blueprintjs/core';
import PropTypes from 'prop-types';
import './ResultCardFlexbox.scss';
import { saveFileFromURL } from '../file_utils';

export const ResultCard = ({ title, description, imgUrl }) => (
  <Card className="resultCard" elevation={Elevation.TWO}>
    <H3 className="resultHeader">{title}</H3>
    {description && <p className="resultCardDescription">{description}</p>}
    <div className="resultCardImgContainer" alt="RESULT IMG">
      <img src={imgUrl} alt="placheolder" className="resultCardImg" />
    </div>
    <Button
      onClick={() => saveFileFromURL(imgUrl, title)}
      icon="download"
      className="resultCArdImageDownloadButton"
      fill
      large
    >
      Download
    </Button>
  </Card>
);

ResultCard.propTypes = {
  title: PropTypes.string.isRequired,
  description: PropTypes.string,
  imgUrl: PropTypes.string.isRequired,
};
ResultCard.defaultProps = {
  description: undefined,
};

export const ResultPage = ({ results }) => {
  const cardArray = results.map((result) => (
    <ResultCard
      key={result.title}
      title={result.title}
      description={result.description}
      imgUrl={result.image_data_url}
    />
  ));

  return <div className="resultPageContainer">{cardArray}</div>;
};

ResultPage.propTypes = {
  // eslint-disable-next-line react/forbid-prop-types
  results: PropTypes.arrayOf(PropTypes.object).isRequired,
};
