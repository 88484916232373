import React from 'react';
import ReactDOM from 'react-dom';
import { BrowserRouter as Router, Redirect, Route, Switch } from 'react-router-dom';
import { FocusStyleManager } from '@blueprintjs/core';
import './index.scss';
import GmailPage from './pages/Gmail';
import reportWebVitals from './reportWebVitals';

ReactDOM.render(
  <React.StrictMode>
    <Router>
      <Switch>
        <Route exact path="/gmail" component={GmailPage} />
        <Redirect from="/" to="/gmail" />
      </Switch>
    </Router>
  </React.StrictMode>,
  document.getElementById('root')
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();

// A button will not be outlined when it is clicked. Only when clicking the tab button
// will a user be able to see the 'focus' outline.
FocusStyleManager.onlyShowFocusOnTabs();
