/* eslint-disable react/prop-types */
/* eslint-disable no-unused-vars */
import { React, useEffect, Component } from 'react';
import { Spinner } from '@blueprintjs/core';
import './TimedSpinner.scss';

// Add zero padding
function zeroPad(number, size = 2) {
  let s = String(number);
  while (s.length < size) {
    s = `0${s}`;
  }
  return s;
}

// Convert time from miliseconds int to hh:mm:ss.S string
function timeFormat(miliseconds) {
  let remaining = miliseconds / 1000;

  const hh = parseInt(remaining / 3600, 10);

  remaining %= 3600;

  const mm = parseInt(remaining / 60, 10);
  const ss = parseInt(remaining % 60, 10);
  const S = parseInt((miliseconds % 1000) / 100, 10);

  return `${zeroPad(hh)}:${zeroPad(mm)}:${zeroPad(ss)}.${S}`;
}

class TimedSpinner extends Component {
  constructor(props) {
    super(props);
    this.state = { time: 0, intervalRef: undefined };
  }

  componentDidMount() {
    const intervalRef = setInterval(() => this.updateTime(100), 100);
    this.setState({ time: 0, intervalRef });
  }

  componentWillUnmount() {
    const { intervalRef } = this.state;
    clearInterval(intervalRef);
    this.setState({ time: 0, intervalRef: undefined });
  }

  updateTime(extraTime) {
    const { time } = this.state;
    this.setState({ time: time + extraTime });
  }

  render() {
    const { time } = this.state;
    const { size } = this.props;
    return (
      <div
        className="spinner_container"
        style={{ width: `${size}px`, height: `${size}px` }}
      >
        <div className="spinner_wrap">
          <Spinner size={size} />
        </div>
        <div className="spinner_wrap" style={{ fontSize: `${size / 6}px` }}>
          <span>{timeFormat(time)}</span>
        </div>
      </div>
    );
  }
}

TimedSpinner.defaultProps = {
  size: 100,
};

export default TimedSpinner;
