import React from 'react';
import { H3, HTMLTable, Icon } from '@blueprintjs/core';
import './StatusTable.scss';
import PropTypes from 'prop-types';

const StatusTable = ({ rows }) => {
  const rowArray = rows.map((row) => (
    <tr key={row.name}>
      <td>{row.name}</td>
      <td>{row.status}</td>
      <td>
        <Icon
          icon={row.isReady ? 'tick-circle' : 'ban-circle'}
          intent={row.isReady ? 'success' : 'danger'}
        />
      </td>
    </tr>
  ));

  return (
    <div>
      <H3>Status</H3>
      <HTMLTable className="progressTable">
        <tbody>{rowArray}</tbody>
      </HTMLTable>
    </div>
  );
};

StatusTable.propTypes = {
  rows: PropTypes.arrayOf(PropTypes.object).isRequired,
};

export default StatusTable;
