/* eslint-disable jsx-a11y/media-has-caption */
import { React, useState, useEffect } from 'react';
import useLocalStorage from 'react-use-localstorage';
import {
  Alert,
  Button,
  ButtonGroup,
  Callout,
  ControlGroup,
  FileInput,
  H1,
  H2,
  H3,
  H4,
  H5,
} from '@blueprintjs/core';
import {
  parseEmailsToTables,
  analyzeEmailTables,
  runPythonAsync,
} from './backend';
import { ResultPage } from '../../components/ResultCardFlexbox';
import { saveFileFromBase64 } from '../../file_utils';
import StatusTable from '../../components/StatusTable';
import TimedSpinner from '../../components/TimedSpinner';
import MailchimpSubscribe from '../../components/MailchimpSubscribe';
import takeoutInitVideo from './takeout_export_demo.mp4';
import takeoutDownloadVideo from './takeout_download.mp4';
import takeoutUnzipVideo from './takeout_unzip.mp4';
import stalkmyselfVideo from './stalkmyself.mp4';

const GmailPage = () => {
  // LOCAL STATES
  const [selectedFiles, setSelectedFiles] = useState();
  const [alertState, setAlertState] = useState({
    isOpen: false,
    message: undefined,
  });
  const [resultImageState, setResultImageState] = useState();
  const [amazonDataLocalStorage, setAmazonDataLocalStorage] =
    useLocalStorage('amazonData');
  const [amazonData, setAmazonDataState] = useState(
    amazonDataLocalStorage || undefined
  );
  const setAmazonData = async (value) => {
    await Promise.all([
      setAmazonDataState(value),
      setAmazonDataLocalStorage(value || ''),
    ]);
  };
  const [emailDataLocalStorage, setEmailDataLocalStorage] =
    useLocalStorage('emailData');
  const [emailData, setEmailDataState] = useState(
    emailDataLocalStorage || undefined
  );
  const setEmailData = async (value) => {
    await Promise.all([
      setEmailDataState(value),
      setEmailDataLocalStorage(value || ''),
    ]);
  };
  const [isPyodideLoaded, setIsPyodideLoaded] = useState(false);
  const [inProgressStatus, setInProgressStatus] = useState(undefined);
  const [errorMessage, setErrorMessage] = useState(undefined);

  const getStatusRows = () => {
    const firstRow = {
      name: 'Data Engine',
      status: isPyodideLoaded ? 'Loaded' : 'Loading',
      isReady: isPyodideLoaded,
    };
    // let secondRowIsReady = false;
    // let secondRowStatus = 'Waiting on you!';
    // if (emailData) {
    //   secondRowIsReady = true;
    //   secondRowStatus = 'Not Needed';
    // }
    // if (selectedFiles) {
    //   secondRowIsReady = true;
    //   secondRowStatus = 'Selected';
    // }
    // const secondRow = {
    //   name: 'Mbox File',
    //   status: secondRowStatus,
    //   isReady: secondRowIsReady,
    // };
    // const thirdRow = {
    //   name: 'Parsed Email Data',
    //   status: emailData ? 'Parsed' : 'Need to Parse',
    //   isReady: emailData,
    // };
    return [firstRow];
    // return [firstRow, secondRow, thirdRow];
  };

  // on startup, submit a dummy Python code exection to test for ready state
  const validatePythonWorking = async () => {
    try {
      const result = await runPythonAsync(`"confirm"`, {});
      if (result === 'confirm') {
        setIsPyodideLoaded(true);
      } else {
        console.error(`Confirmation was ${result} not "confirm"`);
      }
    } catch {
      console.error('Something broke with Pyodide :(');
    }
  };
  useEffect(validatePythonWorking, []);

  const getFileNames = (files) => {
    const res = [];
    if (files !== null) {
      for (let i = 0; i < files.length; i += 1) {
        res.push(files[i].name);
      }
    }
    return res.join(', ');
  };

  const handleClear = async () => {
    await Promise.all([
      setResultImageState(undefined),
      setEmailData(undefined),
      setAmazonData(undefined),
      setSelectedFiles(undefined),
      setErrorMessage(undefined),
    ]);
  };

  const remakeGraphs = async () => {
    if (!emailData) {
      console.error('Cannot remake graphs without data!');
    } else {
      const imageState = await analyzeEmailTables(emailData, amazonData);
      setResultImageState(imageState);
    }
  };

  const handleRun = async () => {
    if (!emailData) {
      setInProgressStatus(
        'Reading all your emails! Expect this to take between one and five minutes per gigabyte of emails...'
      );
      try {
        const { amazon_order_csv_b64: amazonCSV, email_csv_b64: emailCSV } =
          await parseEmailsToTables(selectedFiles[0]);
        await Promise.all([
          setEmailData(emailCSV),
          setAmazonData(amazonCSV || undefined),
        ]);
      } catch (error) {
        setErrorMessage(error.message);
        setInProgressStatus(undefined);
        return;
      }
    }
    setInProgressStatus(
      'Analyzing your emails! This could take about a minute...'
    );
    try {
      await remakeGraphs();
    } catch (error) {
      setErrorMessage(error.message);
    }

    setInProgressStatus(undefined);
  };

  useEffect(async () => {
    if (
      isPyodideLoaded &&
      !inProgressStatus &&
      !resultImageState &&
      !errorMessage &&
      (selectedFiles || emailData) // &&
      // getStatusRows().every((row) => row.isReady)
    ) {
      await handleRun();
    }
  });

  return (
    <section className="container">
      <div className="inputForm">
        <div className="heading">
          <H1 className="bp3-heading withAbout">
            Go ahead and <span className="accent">STALK YOURSELF</span>
          </H1>
        </div>
        <div className="body">
          <div>
            <p>Snoop your own data. Learn something new.</p>
            <br />
            <H3>Instructions</H3>
            <H4 style={{ textAlign: 'left' }}>
              0. For best results, use a desktop browser
            </H4>
            <p style={{ textAlign: 'justify' }}>
              This site <i>can</i> run on some phones, but it may require
              leaving the page open for a long time.
            </p>
            <H4 style={{ textAlign: 'left' }}>1. Grab your data</H4>
            <p style={{ textAlign: 'justify' }}>
              Head to{' '}
              <a
                href="https://takeout.google.com/"
                target="_blank"
                rel="noopener noreferrer"
              >
                takeout.google.com
              </a>{' '}
              to export your email data. Remember to select just the
              &quot;mail&quot; entry and to set a high limit for the size of
              your zip file (we recommend 50GB).
            </p>
            <br />
            <div className="tutorialVideo">
              <video src={takeoutInitVideo} loop autoPlay controls />
            </div>
            <br />
            <p>Starting a Google Takeout export.</p>
            <br />

            <H4 style={{ textAlign: 'left' }}>
              2. Wait for an email from Google with a download link
            </H4>

            <p style={{ textAlign: 'justify' }}>
              It can take Google a few hours to export a big inbox. Once Google
              is done, Google will send an email with a download link to your{' '}
              <a
                href="https://mail.google.com/"
                target="_blank"
                rel="noopener noreferrer"
              >
                GMail
              </a>
              . If you do not see an email from Google, make sure you are logged
              into the same account that you used in Takeout. You can also head
              to{' '}
              <a
                href="https://takeout.google.com/takeout/downloads"
                target="_blank"
                rel="noopener noreferrer"
              >
                takeout.google.com/takeout/downloads
              </a>{' '}
              to check if your data is ready for download.
            </p>
            <br />

            <H4 style={{ textAlign: 'left' }}>3. Download your data</H4>

            <div className="tutorialVideo">
              <video src={takeoutDownloadVideo} loop autoPlay controls />
            </div>
            <br />
            <p>
              Your download should start automatically once you click the link
              emailed to you.
            </p>
            <br />

            <H4 style={{ textAlign: 'left' }}>
              4. Extract the <code>.mbox</code> file
            </H4>
            <div className="tutorialVideo">
              <video src={takeoutUnzipVideo} loop autoPlay controls />
            </div>
            <br />
            <p>
              This file is inside the <code>Takeout/Mail</code> folder within
              the <code>takeout.zip</code> file downloaded in step 3.
            </p>
            <br />

            <H4 style={{ textAlign: 'left' }}>5. Stalk yourself</H4>

            <div className="tutorialVideo">
              <video src={stalkmyselfVideo} loop autoPlay controls />
            </div>
            <br />
            <p>
              Select your <code>.mbox</code> file and wait. That&apos;s it!
            </p>
            <br />

            <p style={{ textAlign: 'justify' }}>
              Select your <code>.mbox</code> file in the dialogue box below to
              start the data processing. Once the data engine is loaded (this is
              automatic but can take up to a minute), a progress wheel will
              appear. Processing should take less than five minutes per gigabyte
              of emails.
            </p>

            <p style={{ textAlign: 'justify' }}>
              Once processing is finished, you will be able to view and download
              several charts. The CSV download links will also be enabled,
              allowing you to download you parsed data and explore it in Excel
              or other spreadsheet software. The Amazon Order Data download will
              only be available if your emails contain Amazon order
              confirmations.
            </p>

            <p style={{ textAlign: 'justify' }}>
              Since processing takes a long time, your browser will save the
              results for you so you can come back and view them later. If you
              want to start over with a different <code>.mbox</code> file, just
              click the orange Start Over button.
            </p>
          </div>
          <br />
          <StatusTable rows={getStatusRows()} />
          <br />
          <div>
            <div>
              <H3>Email Mbox Selection</H3>
              <ControlGroup vertical>
                <FileInput
                  fill
                  text={
                    selectedFiles === undefined
                      ? 'Select mbox file'
                      : getFileNames(selectedFiles)
                  }
                  onInputChange={(event) =>
                    setSelectedFiles(event.target.files)
                  }
                  inputProps={{ multiple: false }}
                  large
                />
                <ButtonGroup fill large>
                  <Button
                    icon="download"
                    disabled={inProgressStatus || !emailData}
                    onClick={() =>
                      saveFileFromBase64(
                        emailData,
                        'application/gzip',
                        'emails.csv.gz'
                      )
                    }
                  >
                    Email Data (CSV)
                  </Button>
                  <Button
                    icon="download"
                    disabled={inProgressStatus || !amazonData}
                    onClick={() =>
                      saveFileFromBase64(
                        amazonData,
                        'application/gzip',
                        'amazon_orders.csv.gz'
                      )
                    }
                  >
                    Amazon Order Data (CSV)
                  </Button>
                  <Button
                    intent="warning"
                    disabled={
                      inProgressStatus ||
                      (!(amazonData || emailData || resultImageState) &&
                        !errorMessage)
                    }
                    onClick={handleClear}
                  >
                    Start Over
                  </Button>
                </ButtonGroup>
              </ControlGroup>
            </div>
            <br />
            {inProgressStatus && (
              <div>
                <TimedSpinner size="120" /> <H5>{inProgressStatus}</H5>
              </div>
            )}
            {errorMessage ? (
              <Callout intent="danger" title="Something went wrong.">
                {errorMessage}
              </Callout>
            ) : undefined}
            <br />
          </div>
        </div>
      </div>
      {resultImageState && (
        <>
          <H1>Results</H1>
          <ResultPage results={resultImageState || []} />
        </>
      )}

      <Alert
        confirmButtonText="Okay"
        isOpen={alertState.isOpen}
        onClose={() =>
          setAlertState((prevState) => ({ ...prevState, isOpen: false }))
        }
        canEscapeKeyCancel
        canOutsideClickCancel
      >
        <p>
          <b>Error:</b> {alertState.message}
        </p>
      </Alert>
      <MailchimpSubscribe />
      <div className="inputForm" style={{ padding: '10px' }}>
        <H2>FAQ</H2>
        <H3>Can you read my emails if I use this website?</H3>
        <p style={{ textAlign: 'justify' }}>
          Nope! The whole site runs only inside your web browser, meaning
          nothing is uploaded or shared with anyone. Once the page fully loads,
          you can even disconnect from the internet and it will still work.
        </p>
        <H3>Why did you build this website?</H3>
        <p style={{ textAlign: 'justify' }}>
          Many of the apps and websites we use are designed to stalk us.
          Companies like Google and Facebook collect a lot of useful data on us
          when we use their products and services, but our use of this data is
          often quite limited. This site seeks to start a conversation around
          questions like &quot;Can I take control of this data too? Can I use it
          myself?&quot;
        </p>
        <H3>How did you build this website?</H3>
        <p style={{ textAlign: 'justify' }}>
          This website uses <a href="https://pyodide.org/">Pyodide</a>, a Python
          distribution that runs in your browser. I built the email-parsing
          scripts in Python, then deployed that Python straight into your
          browser.
        </p>
        <H3>How can I contact you?</H3>
        <p style={{ textAlign: 'justify' }}>
          If you want me to stay in touch with you, you can use the form above.
          If you need to get in touch with me (like if you found a bug or typo),
          you can email me at{' '}
          <a href="mailto:contact@lukemerrick.com">contact@lukemerrick.com</a>.
        </p>
      </div>
    </section>
  );
};

export default GmailPage;
