const saveFileFromURL = (url, fileName) => {
  const saver = document.createElement('a');
  saver.href = url;
  saver.download = fileName;
  document.body.appendChild(saver);
  saver.dispatchEvent(new MouseEvent('click'));
  document.body.removeChild(saver);
};

const saveFileFromBase64 = (b64String, mimeType, fileName) => {
  const url = `data:${mimeType}/;base64,${b64String}`;
  saveFileFromURL(url, fileName);
  URL.revokeObjectURL(url);
};

export { saveFileFromURL, saveFileFromBase64 };
